import React from "react"
import BasicList from "../../components/BasicList"
import BlogTitle from "../../components/BlogTitle"
import Container from "../../components/Container"
import Hero from "../../components/Hero"
import HeroLead from "../../components/HeroLead"
import HeroTitle from "../../components/HeroTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import Text from "../../components/Text"
import links from "../../content/links"
import metaImg from "../../images/app-buyer-guide.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Mobiilisovelluksen ostajan opas"
      description="Tässä artikkelissa käymme läpi muutamia pointteja, mitä ottaa huomioon mobiilisovelluksen hankkimisessa."
      pageImage={metaImg}
      pageUrl={links.blogSites.mobileAppBuyerGuide}
    />

    <section id="hero">
      <Hero hideContact>
        <HeroTitle>
          <h1>
            <span className="block">Mobiilisovelluksen</span>
            <span className="block text-indigo-400">ostajan opas</span>
          </h1>
        </HeroTitle>
        <HeroLead>Kirjoittanut: Henrik Salmela</HeroLead>
      </Hero>
    </section>

    <section>
      <Container>
        <Text>
          Mobiilikäyttäjien määrä saavutti 4.61 miljardia vuonna 2016, ja
          mobiililaitteilla käytetystä ajasta 86 % kuluu mobiilisovellusten
          käyttämiseen. Mobiilisovellus on erinomainen tapa kasvattaa
          liikevaihtoa tai sitouttaa asiakkaita.
        </Text>
        <Text>
          Tässä kerättynä muutamia pointteja mobiilisovelluksen hankkimisesta.
        </Text>
        <BlogTitle>Mihin tähtäät?</BlogTitle>
        <Text>
          Varmista ensin, että sinulla on loppuun mietitty business case tai
          tavoite. Anna tavoitteen muokata sovellustasi. Mieti tarkasti, miten
          aiot palvella asiakkaitasi ja mitä ominaisuuksia siihen tarvitaan.
          Vaikka kaikkien ominaisuuksien lisääminen ensimmäiseen versioon
          kuulostaakin houkuttelevalta, älä ota käyttöön kuin ydintoimintoja
          varten tarkoitetut ominaisuudet. 40 hajanaisen ominaisuuden lisäämisen
          sijaan valitset vain ne tärkeimmät.
        </Text>
        <Text>
          Uusien ominaisuuksien lisääminen tuleviin versioihin on
          kannattavampaa. Tällöin tarjoat sovellukselle alati kehittyvän
          palvelun ja suunnitelman.
        </Text>
        <BlogTitle>Realistinen aikataulu</BlogTitle>
        <Text>
          Aseta realistinen deadline yhdessä ohjelmoijan kanssa. Esi- ja
          jälkityövaiheet voivat kestää kauemmin kuin suunnittelit.
          Julkaiseminen testikäyttäjille saattaa kestää viikkoja. Pelkästään
          palautteen kerääminen testikäyttäjiltä kestää kauemmin kuin helposti
          kuvittelisi.
        </Text>
        <Text>
          Älä laiminlyö markkinointitarpeita. Kun ensimmäiset käyttäjät ovat
          antaneet palautteensa sisältäen korjaus- tai muutosehdotuksensa,
          arvioidaan markkinointistrategiaa uudestaan. Muutosten jälkeen
          strategian tulisi olla siinä kunnossa, että se voidaan siirtää
          käytäntöön.
        </Text>
        <BlogTitle>Testaa</BlogTitle>
        <Text>
          Mobiilisovelluksesi testaus on erityisen tärkeää. Käyttäjillä testaus
          on paras tapa saada nopeaa palautetta ja varmistaa, että kaikki toimii
          niin kuin pitäisi. Testaa kaikista tärkeimmät käyttöaiheet:
        </Text>
        <BasicList>
          <li>Toimiiko sovellus kaikilla laitteilla ja alustoilla</li>
          <li>Tekeekö mobiilisovellus mitä halutaan</li>
          <li>Onko mobiilisovellus käyttäjäystävällinen</li>
        </BasicList>
        <BlogTitle>Älä katso vain hintaa, vaan keskity laatuun</BlogTitle>
        <Text>
          Tänä päivänä mobiilisovellusmarkkinoilla on kovempi kilpailu kuin
          koskaan aikaisemmin, samaan aikaan asiakkaat ovat entistä
          laatutietoisempia. Käyttäjät ovat vaativampia siitä mitä sovelluksia
          he tarvitsevat ja haluavat käyttää. Onnistunut julkaisu
          mobiilisovellukselle vaati painotusta enemmänkin laadullisiin
          ratkaisuihin.
        </Text>
        <Text>Ammattilaisen neuvot budjetin hallintaan:</Text>
        <BasicList>
          <li>
            Cross-platform sovellukset ovat halvempia kehittää kuin
            mobiilisovelluksen valmistaminen kaikille alustoille natiivina.
          </li>
          <li>
            Pidä budjetti joustavana. Mobiilisovelluksissa kyetään harvoin
            yleiseen hinnoitteluun. Jokainen sovellus on uniikki ja siksi
            budjettia on vaikea verrata toisiin mobiilisovelluksiin.
          </li>
          <li>
            Säästä osa budjetista julkaisun jälkeiseen kehittämiseen. Karkeasti
            20-30% koko kehitysbudjetista pitäisi sijoittaa ylläpitoon sekä
            päivityksiin, uusien laitteiden vaatimuksiin, käyttöjärjestelmän
            parannuksiin sekä muihin tarvittaviin päivityksiin.
          </li>
        </BasicList>
        <BlogTitle>Sinun ohjelmistokehittäjäsi</BlogTitle>
        <Text>
          Ensimmäinen julkaisu on vasta alkua pitkään yhteiseen matkaan
          sovelluksesi kanssa. Joka neljäs käyttäjä, kuka lataa
          mobiilisovelluksesi käyttää sitä vain kerran eikä avaa sitä enää
          koskaan sen jälkeen. Siksi sovelluksen kehittäminen ja asiakkaiden
          sitouttaminen on äärimmäisen tärkeää. Kun uusi Android tai iOS versio
          julkaistaan, sinun tulee huolehtia, että ohjelmistosi toimii halutulla
          tavalla vielä tulevaisuudessakin.
        </Text>
        <Text>Kysy myös itseltäsi:</Text>
        <BasicList>
          <li>Miten käyttäjät antavat palautetta mobiilisovelluksesta?</li>
          <li>Kuka käsittelee käyttäjä palautetta?</li>
          <li>
            Jos mobiilisovellus saa huonoja arvosteluja, kuka alkaa parantamaan
            tilannetta?
          </li>
        </BasicList>
        <Text>Yritä löytää kehittäjä, joka on kanssasi vuosia.</Text>
      </Container>
    </section>
  </Layout>
)
